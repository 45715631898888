export const PrivacyPolicy = () => {
    return <div className="website">
        <section className="page-head">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="description">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="mb-4">
                            <h4>Introduction</h4>
                            <p>Indus Cyber Tech values your privacy. This Privacy Policy outlines the information we collect, how we use it, and your rights regarding your data.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Information We Collect</h4>
                            <p>We may collect both personal and non-personal information:</p>
                            <ul>
                                <li><strong>Personal Information:</strong>Includes your name, email address, phone number, and any other information you provide when contacting us or registering on our site.</li>
                                <li><strong>Non-Personal Information:</strong>Includes your IP address, browser type, and usage data to help us improve the user experience.</li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <h4>How We Use Your Information</h4>
                            <p>We use your information to:</p>
                            <ul>
                                <li>Provide and improve our services</li>
                                <li>Respond to inquiries and provide customer support</li>
                                <li>Send you updates and promotional content (only if you opt in)</li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <h4>Data Sharing and Security</h4>
                            <p>We do not sell or rent your information to third parties. We implement industry-standard security measures to protect your personal information from unauthorized access.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Cookies</h4>
                            <p>Our website uses cookies to enhance your browsing experience. You may choose to disable cookies through your browser settings, though this may affect some functionality of the site.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Your Rights</h4>
                            <p>ou have the right to access, modify, or delete your personal information at any time. If you wish to exercise these rights, please contact us at <a href="mailto:business@induscybertech.com">[business@induscybertech.com]</a>.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Changes to the Privacy Policy</h4>
                            <p>We reserve the right to modify this Privacy Policy as needed. We will notify you of any changes by updating the date at the top of this page.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Contact Information</h4>
                            <p>For any questions or concerns about your privacy, please contact us at <a href="mailto:business@induscybertech.com">[business@induscybertech.com]</a>.</p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
}