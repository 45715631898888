export const Disclaimer = () => {
    return <div className="website">
        <section className="page-head">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Disclaimer</h1>
                        <p className="pageSubTitle"> Policy</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="description">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="mb-4">
                            <h4>General Information</h4>
                            <p>The information provided on Indus Cyber Tech’s website is for general informational purposes only. While we strive to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the accuracy, reliability, or availability of the information.</p>
                        </div>
                        <div className="mb-4">
                            <h4>No Professional Advice</h4>
                            <p>The content on this website does not constitute professional advice of any kind. You should consult with qualified professionals for advice tailored to your specific needs.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Limitation of Liability</h4>
                            <p>Indus Cyber Tech will not be liable for any loss or damage arising from the use of this website or reliance on its content, including but not limited to indirect or consequential loss.</p>
                        </div>
                        <div className="mb-4">
                            <h4>External Links</h4>
                            <p>Our website may contain links to third-party websites. We do not control, endorse, or assume responsibility for any content or practices of these external sites.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Changes to the Disclaimer</h4>
                            <p>We may update this Disclaimer from time to time. Your continued use of the website signifies your acceptance of any changes.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Contact Information</h4>
                            <p>If you have any questions regarding this Disclaimer, please contact us at <a href="mailto:business@induscybertech.com">[business@induscybertech.com]</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}