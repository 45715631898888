export const TermsConditions = () => {
    return <div className="website">
        <section className="page-head">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Terms and Conditions</h1>
                        <p className="pageSubTitle"> Policy</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="description">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="mb-4">
                            <h4>Introduction</h4>
                            <p>Welcome to Indus Cyber Tech. By using our website and services, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree with these terms, you should not use this website or our services.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Use of the Website</h4>
                            <p>You may use the website for lawful purposes only. You agree not to engage in any activities that may disrupt or interfere with the proper functioning of the website.</p>
                        </div>
                        <div className="mb-4">
                            <h4>User Accounts</h4>
                            <p>To use certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Intellectual Property Rights</h4>
                            <p>All content, materials, and intellectual property on this website are owned by Indus Cyber Tech. Unauthorized use, reproduction, or distribution of this content is strictly prohibited.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Limitation of Liability</h4>
                            <p>Indus Cyber Tech is not liable for any damages that may occur from using this website, including but not limited to direct, indirect, or incidental damages.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Changes to the Terms</h4>
                            <p>We reserve the right to modify these Terms and Conditions at any time. Your continued use of the website after any changes means you accept the new terms.</p>
                        </div>
                        <div className="mb-4">
                            <h4>Contact Information</h4>
                            <p>If you have any questions about these terms, please contact us at <a href="mailto:business@induscybertech.com">[business@induscybertech.com]</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}