export const Process = () => {
    return <section className="ourProcess">
        <div className="container">
            <div className="row">
                <div className="col-12 commonHeading">
                    <h1>Our Process</h1>
                    <p>At Indus Cyber Tech, our development process is designed to ensure that your projects are completed efficiently, effectively, and in alignment with your business goals.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-sm-6">
                    <div className="content">
                        <h3>Understanding Your Needs</h3>
                        <div className="icon">
                            <i className="fa-solid fa-question"></i>
                        </div>
                        <p>At Indus Cyber Tech, our development process is designed to ensure that your projects are completed efficiently, effectively, and in alignment with your business goals.</p>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="content">
                        <h3>Sourcing the Right Talent</h3>
                        <div className="icon">
                            <i className="fa-solid fa-people-group"></i>
                        </div>
                        <p>Our extensive network of skilled IT professionals allows us to find the perfect match for your project needs.</p>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="content">
                        <h3>Seamless Collaboration</h3>
                        <div className="icon">
                            <i className="fa-solid fa-handshake-simple"></i>
                        </div>
                        <p>Our experts integrate seamlessly with your team, providing full support throughout the project’s lifecycle.</p>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="content">
                        <h3>Delivering Results</h3>
                        <div className="icon">
                            <i className="fa-solid fa-file-zipper"></i>
                        </div>
                        <p>We are committed to delivering high-quality, on-time solutions that drive measurable results and business growth.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}